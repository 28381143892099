import React from 'react';
import PropTypes from 'prop-types';
import { StyledEventsLink, LW } from 'UI/navigation/TopLinkStyles';
import styled, { withTheme } from 'styled-components';
import Constants from '../../../../project';
import { IMG } from './core__topLinkStyles';

const EventsLink = ({
  active,
  label,
  onClick,
  disable,
  showOSTopLink,
  gameIcon,
  casinoTheme,
  promotionTheme,
  ...props
}) => {
  let image;
  if (gameIcon) {
    image = Constants.imagesUrl + gameIcon + '-80x80.png';
  }
  if (showOSTopLink) {
    return (
      <LW active={active} disable={disable}>
        <StyledEventsLink
          title={label}
          showOSTopLink={showOSTopLink}
          onClick={onClick}
          {...props}
        >
          {label}
        </StyledEventsLink>
      </LW>
    );
  }
  return (
    <StyledEventsLink
      title={label}
      active={active}
      onClick={onClick}
      {...props}
      casinoTheme={casinoTheme}
      promotionTheme={promotionTheme}
      disable={disable}
    >
      <div>
        {image && <IMG src={image}></IMG>}
        {label}
      </div>
    </StyledEventsLink>
  );
};

EventsLink.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  showOSTopLink: PropTypes.bool,
  gameIcon: PropTypes.string,
  casinoTheme: PropTypes.bool,
  promotionTheme: PropTypes.bool,
};

export const EventsAppTitle = styled(EventsLink)`
  border: none;
`;

export default withTheme(EventsLink);
