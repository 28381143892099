import styled, { css } from 'styled-components';
import { BRAND, FONT, GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const StyledEventsLink = styled.a`
  font-family: 'Nunito Sans', sans-serif;
  display: inline-block;
  min-width: fit-content;
  line-height: 1;
  margin-right: 16px;
  margin-left: 8px;
  margin-bottom: 1em;
  margin-top: 8px;
  font-size: 16px;
  padding: 12px;
  color: ${({ active, theme }) =>
    active
      ? theme.dark
        ? BRAND.primary
        : FONT.secondary
      : theme.dark
      ? FONT.light
      : FONT.dark};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => (theme.dark ? GREYS.white : FONT.secondary)};
  }

  &:before {
    display: block;
    content: attr(title);
    font-weight: 800;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: ${({ theme }) =>
        theme.dark
          ? `2px solid ${GREYS.silver}`
          : `2px solid ${BRAND.secondary}`};
      font-weight: 800;
      &:hover {
        color: ${({ theme }) => (theme.dark ? BRAND.primary : FONT.secondary)};
      }
    `}
  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

    ${({ theme }) =>
    theme.dark &&
    css`
      color: ${({ active }) => (active ? BRAND.primary : GREYS.white)};
      &:hover {
        color: ${GREYS.white};
        font-weight: 800;
      }
      ${({ active }) =>
        active &&
        css`
          border-bottom: 2px solid ${GREYS.silver};
          font-weight: 800;
          &:hover {
            color: ${BRAND.primary};
          }
        `}
    `}
`;

export const LW = styled.div`
  padding: 16px 16px 12px 16px;
  font-family: 'Nunito Sans', sans-serif;
  display: inline-block;
  margin-bottom: 8px;
  &:first-child {
    margin-left: 0;
  }
  ${({ active }) =>
    active
      ? css`
          border-bottom: 2px solid ${BRAND.secondary};
          font-weight: 800;
        `
      : active &&
        css`
          border-bottom: 2px solid ${BRAND.primary};
          font-weight: 800;
        `}
  font-size: 18px;
  color: ${BRAND.primary};
  cursor: pointer;
  ${({ active }) =>
    active
      ? css`
          &:hover {
            color: ${BRAND.primary};
          }
        `
      : css`
          &:hover {
            color: ${BRAND.secondary};
          }
        `}

  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
export const StyledEventsLink_OS = styled.a`
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
`;
export const Wrap = styled.div`
  ${({ customStyle }) =>
    customStyle &&
    css`
      width: 1024px;
      margin: auto;
      @media (max-width: ${BREAKPOINT_S}) {
        width: 100%;
      }
    `}
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    overflow-y: auto;
  }
`;
export const IMG = styled.img`
  width: 20px;
`;
