import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';

export const reducer = combineReducers({
  data: (state = {}, action) => {
    return { ...state, ...action.data };
  },
});

//this is a thunk middleware function, for initial api call and dispatch to store
export const fetchData = (url, nodeRequest, appConfig) => dispatch => {
  return new Promise(resolve => resolve()).then(
    () => dispatch({ type: 'STORE_DATA', data: { appConfig } }) //dummy dispatch to conform with SSR
  );
};

const composeEnhancers = getComposeEnhancers({ name: 'events-header-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
