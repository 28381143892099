import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PubSub from 'Services/pubsub/core__pubsub';
import PropTypes from 'prop-types';
import { PubsubEvents } from './pubsub/core__pubsub.constants';

/**
 * This file is capable of handling following situations and more to come without affecting any app
 * 1. Theme from app
 * 2. Theme from user choice pubsub
 * 3. Theme from project - any custom logic can be added per project
 * @param {String} theme
 * @returns
 */
export const FSBTheme = ({ theme, children }) => {
  const [themeName, setThemeName] = useState(theme);

  useEffect(() => setThemeName(theme), [theme]);

  useEffect(() => {
    const themeChangedListener = PubSub.listen(
      PubsubEvents.THEME_CHANGED,
      thName => {
        thName && setThemeName(thName);
      }
    );

    return () => {
      themeChangedListener?.unsubscribe();
    };
  }, []);

  return (
    <ThemeProvider
      theme={{
        dark: themeName === 'dark',
        light: themeName === 'light',
        green: themeName === 'colorblind',
        casino: themeName === 'casino',
      }}
    >
      {children}
    </ThemeProvider>
  );
};
FSBTheme.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'colorblind', 'casino']),
  children: PropTypes.node,
};
