import React from 'react';
import PropTypes from 'prop-types';
import replaceAnd from 'Services/translations/core__replaceAnd';
import { EventsLink } from 'UI/navigation/TopLink';
import { Translations } from '../../core__events-header-app';
import project from '../../../../../project';

export const HeaderTabsComponent = ({
  headers,
  activeHeader,
  setActiveHeader,
  casinoTheme,
  promotionTheme,
  appConfig,
}) => {
  const additionalTabs = appConfig?.additionaltabs?.split(',');
  const additionalTabsUrl = appConfig?.additionaltabsurl?.split(',');
  return (
    <React.Fragment>
      {headers &&
        Object.keys(headers).map((key, i_header) => {
          const header = headers[key];
          return (
            header && (
              <EventsLink
                casinoTheme={casinoTheme}
                promotionTheme={promotionTheme}
                onClick={() => {
                  setActiveHeader(key);
                }}
                active={key === activeHeader}
                label={
                  appConfig?.istranslated
                    ? project.JpTimeFormat
                      ? Translations.get(
                          `text.${header
                            .toLowerCase()
                            .replace(' ', '')
                            .replace('-', '')}`
                        )
                      : Translations.get(header)
                    : replaceAnd(header)
                }
                keyValue={i_header}
                key={i_header}
              ></EventsLink>
            )
          );
        })}
      {additionalTabs &&
        additionalTabs?.map((additionalTab, index) => {
          return (
            <EventsLink
              casinoTheme={casinoTheme}
              promotionTheme={promotionTheme}
              onClick={() => {
                window.location = additionalTabsUrl[index];
              }}
              active={additionalTab === activeHeader}
              label={additionalTab}
              keyValue={index}
              key={index}
            ></EventsLink>
          );
        })}
    </React.Fragment>
  );
};
HeaderTabsComponent.propTypes = {
  activeHeader: PropTypes.any,
  headers: PropTypes.object,
  setActiveCatgory: PropTypes.func,
  setActiveHeader: PropTypes.func,
  casinoTheme: PropTypes.bool,
  appConfig: PropTypes.object,
  promotionTheme: PropTypes.bool,
};
