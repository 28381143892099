import {
  LW,
  StyledEventsLink,
  Wrap,
} from 'CORE__UI/navigation/TopLink/core__topLinkStyles';
import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from '../globals/breakpoints';
import { BRAND, GREYS } from '../globals/colours';
const StyledEventsLink_GDWN = styled(StyledEventsLink)`
  color: ${({ active }) => (active ? GREYS.white : GREYS.white)} !important;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
  @media (hover) {
    &:hover {
      color: ${GREYS.white};
      font-weight: 800;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    &:first-child {
      margin-left: 8px;
    }
  }
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid ${BRAND.secondary};
      font-weight: 800;
      @media (hover) {
        &:hover {
          color: ${GREYS.white};
        }
      }
    `}
`;
export { LW, StyledEventsLink_GDWN as StyledEventsLink, Wrap };
