import React from 'react';
import ReactDOM from 'react-dom';

import ScDecoder from '../../../services/json/core__decoder';
import { EventsHeaderReact } from './components/core__eventsHeaderReact';
import createStore, { fetchData } from './core__events-header-app-store';
import { Provider as ReduxProvider } from 'react-redux';
import { stringsToTranslate } from './core__events-header-translations';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('EventHeaderApp');
import { connect } from 'react-redux';
class EventsHeaderApp {
  constructor(properties) {
    Object.assign(this, properties);
    if (this.nodeSSR) {
      Translations.get = properties.Translations?.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations?.getAll?.bind(
        properties.Translations
      );
      return;
    }
    if (this.nodeSSR) return;
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => {
      return {
        appConfig: state.data.appConfig,
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      fetchData: fetchData,
    };

    EventsHeaderReact.serverFetch = {
      fetchData: fetchData,
      createStore: createStore,
      strToTranslate: this.appConfig?.istranslated
        ? __getTranslationsStrings(this.appConfig, stringsToTranslate)
        : stringsToTranslate,
    };

    return connect(mapStateToProps, mapDispatchToProps)(EventsHeaderReact);
  }
}
const __getTranslationsStrings = (appConfig, translationStrings) => {
  translationStrings.push(appConfig?.title);
  return translationStrings;
};
export default EventsHeaderApp;
